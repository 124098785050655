/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiButton-containedWhite
{
  color: #002B48 !important;
}

.btn-bb-slim {
  margin-right: 6px !important;
  /* font-family: Cabin !important; */
  border-radius: 5vh !important;
  transition: all 0.5s !important;
}

.btn-bb-slim:hover {
  transform: scale(1.05);
}

h1 {
  letter-spacing: -2px !important;
  font: 6.4vh Cabin !important;
}

h2 {
  letter-spacing: 0px !important;
  text-align: left !important;
  font: 3.2vh Cabin !important;
}

h3 {
  letter-spacing: 0px !important;
  font: Cabin !important;
}

@media (max-width: 479px) {
  h1 {
    letter-spacing: -2px !important;
    text-align: left !important;
    font: 5vh Cabin !important;
  }

  h2 {
    letter-spacing: 0px !important;
    text-align: left !important;
    font: 2.5vh Cabin !important;
  }
}

.menu-bb {
  transition: all 0.5s !important;
}

.menu-bb:hover {
  transform: scale(1.1) !important;
  font-weight: bold !important;
}


.icon-bb {
  transition: all 0.5s !important;
}

.icon-bb:hover {
  transform: scale(1.4);
  cursor: pointer !important;
}

.card-bb {
  border-radius: 5vh 5vh 5vh 5vh !important;
  margin-top: 20px;
}

.iconBB {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 5rem;
}

.iconBB_sm {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.8em;
  height: 1em;
  display: inline-block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 3rem;
}

.iconBB_md {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.5em;
  height: 1.5em;
  font-size: 5rem;
}

.iconBB_lg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.7em;
  height: 1.2em;
  font-size: 5.6rem;
}

.iconBB_xl {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 6rem;
}

.iconBB_xxl {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.2em;
  height: 1.5em;
  display: inline-block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 6rem;
}

.bb_menu {
  transition: all 1s !important;
}

.bb_menu:hover {
  animation: logo-pulse 1s infinite;
}

@keyframes logo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes logo-color {
  0% {
    fill: #f00;
  }
  25% {
    fill: #0f0;
  }
  50% {
    fill: #00f;
  }
  75% {
    fill: #0f0;
  }
  100% {
    fill: #f00;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(1deg);
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-1deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(1deg);
  }
  35% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-1deg);
  }
  45% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.MuiTypography-root {
  overflow-wrap: break-word;
}


.er_svgmap {
  height: fit-content;
  g#Bologna {
    &:hover,
    &:focus {
      cursor: pointer;
      polygon,
      path {
        fill: #ff6600 !important;
      }
    }
  }
  g#Ferrara {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #cc66ff !important;
      }
    }
  }
  g#Forli-Cesena {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #7f8c8d !important;
      }
    }
  }
  g#Modena {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #E0DD00 !important;
      }
    }
  }
  g#Parma {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #00ff99 !important;
      }
    }
  }
  g#Piacenza {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #006699 !important;
      }
    }
  }
  g#Ravenna {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #e74c3c !important;
      }
    }
  }
  g#Reggio_Emilia {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #edbb99 !important;
      }
    }
  }
  g#Rimini {
    &:hover,
    &:focus {
      cursor: pointer;

      polygon,
      path {
        fill: #669900 !important;
      }
    }
  }
}

.ChipJobER > span {
  text-align: left !important;
  font: 1.6vh Cabin !important;
}

.selectedprov_bologna {
  fill: #ff6600;
}

.selectedprov_ferrara {
  fill: #cc66ff;
}

.selectedprov_forli-cesena {
  fill: #9900cc;
}

.selectedprov_modena {
  fill: #E0DD00;
}

.selectedprov_parma {
  fill: #00ff99;
}

.selectedprov_piacenza {
  fill: #006699;
}

.selectedprov_ravenna {
  fill: #800000;
}

.selectedprov_reggioemilia {
  fill: #6666ff;
}

.selectedprov_rimini {
  fill: #669900;
}
