.colored-JobErToast.swal2-icon-success {
    background-color: #44A180 !important;
  }
  
  .colored-JobErToast.swal2-icon-error {
    background-color: #f5365cd4 !important;
  }
  
  .colored-JobErToast.swal2-icon-warning {
    background-color: #ffb74d !important;
  }
  
  .colored-JobErToast.swal2-icon-info {
    background-color: #3fc3ee !important;
  }
  
  .colored-JobErToast.swal2-icon-question {
    background-color: #87adbd !important;
  }
  
  .colored-JobErToast .swal2-title {
    color: white; 
  }
  
  .colored-JobErToast .swal2-close {
    color: white;
  }
  
  .colored-JobErToast .swal2-html-container {
    color: white;
  }